import {
  Box,
  CircularProgress,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
} from '@mui/material';
import LayersComponent from '../../Components/Layers';
import { MapComponent } from '../../Components/Map';
import { Res } from '../../resources';
import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { useParams } from 'react-router';
import useGentian from '../../core/hookies/gentian.hook';
import {
  ResultSource,
  buildSources,
  buildInspectorTool,
  buildLayersFill,
  buildLayersPatterns,
  buildLayersOutline,
  buildLayersImagery,
} from '../../core/builders/layer.builder';
import { datadogLogs } from '@datadog/browser-logs';

import { useAuth0 } from '@auth0/auth0-react';
import { MAP_STYLES } from '../../Components/Map/controls/MapStyleSwitchControl';
import CloseIcon from '@mui/icons-material/Close';

export const popup = new mapboxgl.Popup({
  closeButton: true,
  closeOnClick: false,
});

export const SurveyResultPage = () => {
  const [selectedCategories, setSelectedCategories] = useState<{ id: string; visible: boolean }[]>([]);
  const { projectId } = useParams();
  const { user } = useAuth0();

  const {
    getResultByProjectId,
    getProjectById,
    updateCoordinateComments,
    getCoordinateCommentsByResultId,
    updateResult,
  } = useGentian();

  const { mutate: saveSharedResultAccess, isIdle } = useGentian().addResult;
  const { data: result, isLoading, refetch } = getResultByProjectId(projectId);
  const { data: project } = getProjectById(projectId);
  const { data: comment } = getCoordinateCommentsByResultId(result?._id);

  const [sources, setSources] = useState<ResultSource[]>([]);
  const [mapLayers, setMapLayers] = useState<any>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isPlanning, setIsPlanning] = useState(false);
  const [drawableArea, setDrawableArea] = useState(null);
  const [habitatTypes, setHabitatTypes] = useState([]);
  const [notesColumnOpen, setNotesColumnOpen] = useState(false);
  const [notes, setNotes] = useState(result?.additionalNotes || '');
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const inspector = React.useRef(new Map<string, string>());

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUpdateCoordinateComments = (comment) => {
    updateCoordinateComments.mutate({ resultData: { _id: result._id }, coordinateComments: comment });
  };

  useEffect(() => {
    if (isIdle && user?.sub && result?.createdBy && user?.sub !== result?.createdBy) {
      saveSharedResultAccess(projectId);
    }
  }, [user, result]);

  useEffect(() => {
    if (result?.layers?.length && sources.length) {
      inspector.current = new Map(buildInspectorTool(sources, result));
    }
  }, [sources]);

  useEffect(() => {
    if (!project) return;
    setDrawableArea(project?.AOI?.features?.[0]);
  }, [project]);

  const handleOnMouseEnter = (
    map: mapboxgl.Map,
    sourceId: string,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  ) => {
    popup.setLngLat(e.lngLat).setHTML(inspector.current.get(sourceId)).addTo(map);
  };

  const handleCheckboxChange = (checked, data) => {
    const selectedCategory = selectedCategories.filter((c) => c.id.startsWith(data.id));
    if (selectedCategory?.length) {
      selectedCategory.forEach((c) => (c.visible = checked));
    } else {
      selectedCategories.push({ id: data.id, visible: checked });
    }
    setSelectedCategories([...selectedCategories]);
  };

  const handleOnSelect = (selected, layer) => {
    const prefix = `${layer.resultId}_${layer.details.columnName}_`;
    selectedCategories
      ?.filter((c) => c?.id?.startsWith(prefix))
      .forEach((c) => {
        const visible =
          layer.details.classes.find((cl) =>
            c.id.replace(prefix, '').startsWith(cl.category.toString().replace(/\s/g, '-')),
          )?.defaultEnabled !== false;
        c.visible = selected && visible;
      });
    setSelectedCategories([...selectedCategories]);
  };

  useEffect(() => {
    if (mapLayers?.length) {
      const allClasses = result?.layers
        .map((layer) =>
          layer.details.classes.map((c) => ({
            id: `${layer.resultId}_${layer.details.columnName}_${c.category.toString().replace(/\s/g, '-')}`,
            visible: layer.details.defaultEnabled !== false && c.defaultEnabled !== false, // Default to true if undefined
          })),
        )
        .flat();
      const selCategories = mapLayers?.map((mapLayer) => ({
        id: mapLayer.id,
        visible: allClasses.find((c) => (mapLayer.id as string).startsWith(c.id))?.visible !== false || false,
      }));
      setSelectedCategories([...selCategories]);
    }
  }, [mapLayers]);

  useEffect(() => {
    if (result?.layers) {
      // Filter layers to get only those with displayName 'Habitats'
      const habitatLayer = result.layers.find((layer) => layer.displayName === 'Habitats');

      if (habitatLayer) {
        // Extract class names from the pieChart
        const habitatOptions = habitatLayer.details.classes.map((item) => item.displayName);
        setHabitatTypes(habitatOptions);
        console.log('Habitat Class Names:', habitatOptions);
      } else {
        console.log('No habitat layer found');
      }
    }
  }, [result]);

  useEffect(() => {
    setNotes(result?.additionalNotes || '');
  }, [result]);

  const handleSaveNotes = () => {
    // Update the result with the new notes
    const updatedResult = { ...result, additionalNotes: notes };
    updateResult.mutate(updatedResult, {
      onSuccess: async () => {
        await refetch();
        setSnackbarMessage('Notes Saved');
        setSnackbarOpen(true);
        setNotesColumnOpen(false);
      },
      onError: (error) => {
        setSnackbarMessage('Error saving notes');
        setSnackbarOpen(true);
        datadogLogs.logger.error('Error saving notes', { error });
      },
    });
  };

  const handleCancelNotes = () => {
    setConfirmCancelOpen(true);
  };

  const handleConfirmCancel = () => {
    setNotes(result?.additionalNotes || '');
    setNotesColumnOpen(false);
    setConfirmCancelOpen(false);
  };

  const handleCloseConfirmCancel = () => {
    setConfirmCancelOpen(false);
  };

  const handleCloseNotesColumn = () => {
    setNotesColumnOpen(false);
  };

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return isLoading ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : !result?.layers?.length ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>No data found</h1>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {isMobile ? (
        <>
          <Box
            sx={{
              width: '10vw',
              height: 'calc(100vh - var(--header-height) - var(--footer-height))',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleDrawerToggle}
          >
            <Typography variant="h6" sx={{ transform: 'rotate(-90deg)' }}>
              Layers
            </Typography>
          </Box>
          <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <LayersComponent
              result={result}
              onCheckboxChange={handleCheckboxChange}
              onSelect={handleOnSelect}
              notesColumnOpen={notesColumnOpen}
              setNotesColumnOpen={setNotesColumnOpen}
            />
          </Drawer>
        </>
      ) : (
        <Box
          sx={{
            width: '320px',
            height: 'alto',
            overflowY: 'auto',
            overflowX: 'hidden',
            minWidth: '320px',
            backgroundColor: 'white',
          }}
        >
          <LayersComponent
            result={result}
            onCheckboxChange={handleCheckboxChange}
            onSelect={handleOnSelect}
            notesColumnOpen={notesColumnOpen}
            setNotesColumnOpen={setNotesColumnOpen}
          />
        </Box>
      )}

      <MapComponent
        resultsOnly
        coordinateComment={comment}
        onUpdateCoordinateComment={handleUpdateCoordinateComments}
        accessToken={Res.components.maps.MAPBOX_TOKEN || ''}
        hideMapStyleSwitchControl
        style={MAP_STYLES.SATELLITE}
        sources={sources}
        drawableArea={drawableArea}
        habitatOptions={habitatTypes}
        onLoad={(map) => {
          const sources = result?.layers
            ?.map((l) => buildSources(l, result.shapes))
            .flat()
            .reverse();
          const patterns = buildLayersPatterns(sources, result);
          const layers = [
            ...(buildLayersImagery(sources) || []),
            ...(buildLayersFill(sources, result) || []),
            ...(buildLayersOutline(sources, result) || []),
          ];

          setSources(sources);
          setMapLayers([...layers]);

          sources.forEach((source) => {
            if (map.getSource(source.id)) {
              map.removeSource(source.id);
            }
            map.addSource(source.id, source.data);

            if (!isPlanning) {
              map.on('mousedown', source.id, (e) => {
                handleOnMouseEnter(map, source.id, e as any);
              });

              map.on('mouseenter', source.id, () => {
                map.getCanvas().style.cursor = 'pointer';
              });

              map.on('mouseleave', source.id, () => {
                map.getCanvas().style.cursor = '';
              });
            }
          });

          Object.entries(patterns).forEach(([id, pattern]) => {
            map.loadImage(pattern, (error, image) => {
              if (error) {
                datadogLogs.logger.error('Error loading image', {
                  error,
                  name: 'loadImage',
                  id,
                  pattern,
                });
                throw error;
              }
              if (map.hasImage(id)) {
                map.removeImage(id);
              }
              map.addImage(id, image);
            });
          });

          layers?.forEach((layer) => {
            if (map.getLayer(layer.id)) {
              map.removeLayer(layer.id);
            }
            map.addLayer(layer);
          });

          if (sources.length) {
            const boundSource = (sources.find((s) => !!(s.data as any)?.data)?.data as any)?.data;
            if (boundSource) {
              map.fitBounds(turf.bbox(boundSource) as any, {
                padding: 20,
                maxZoom: 14,
              });
            }
          }
        }}
        filteredLayers={selectedCategories}
        sx={{
          width: isMobile ? '90vw' : 'calc(100vw - 320px)',
          height: 'calc(100vh - var(--header-height) - var(--footer-height))',
        }}
      />
      {notesColumnOpen && (
        <Box
          sx={{
            width: '300px',
            minWidth: '300px',
            bgcolor: '#f5f5f5',
            borderLeft: '1px solid #ddd',
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Project Notes
            </Typography>
            <IconButton onClick={handleCloseNotesColumn}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            sx={{
              mb: 2,
              '& .MuiInputBase-input': {
                lineHeight: '1.75em',
                height: 'calc(100vh - var(--header-height) - var(--footer-height) - 128px) !important ',
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleSaveNotes} sx={{ mr: 1 }}>
              Save
            </Button>
            <Button variant="outlined" color="primary" onClick={handleCancelNotes}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}
      <Dialog
        open={confirmCancelOpen}
        onClose={handleCloseConfirmCancel}
        aria-labelledby="confirm-cancel-dialog-title"
        aria-describedby="confirm-cancel-dialog-description"
      >
        <DialogTitle id="confirm-cancel-dialog-title">
          Are you sure you want to cancel your changes?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" id="confirm-cancel-dialog-description">
            Any unsaved changes will be lost.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};
