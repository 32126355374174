export const habitatDescriptionsMap = {
  '16': '16 - Tall forbs',
  '206': '206 - Felled',
  '26': '26 - Wood-pasture and parkland',
  '27': '27 - Traditional orchards',
  '41': '41 - Ponds (non-priority habitat)',
  '45': '45 - Reservoirs',
  '46': '46 - Ornamental lake or pond',
  '510': '510 - Bare ground',
  '56': '56 - Depressions on peat substrates (H7150)',
  '616': '616 - Allotments',
  '80': '80 - Open mosaic habitats on previously developed land',
  '81': '81 - Ruderal/Ephemeral',
  '82': '82 - Vacant or derelict land',
  '828': '828 - Vegetated garden',
  '829': '829 - Unvegetated garden',
  '842': '842 - Ground based green wall',
  '843': '843 - Facade-bound green wall',
  '845': '845 - Ground level planters',
  '847': '847 - Introduced shrub',
  '848': '848 - Sustainable drainage system',
  '849': '849 - Bioswale',
  '85': '85 - Actively worked sand pit quarry or open cast mine',
  '850': '850 - Rain garden',
  '87': '87 - Biodiverse green roof',
  '88': '88 - Intensive green roof',
  '89': '89 - Other green roof',
  '90': '90 - Cemeteries and churchyards',
  'A1.1': 'A1.1 - High energy littoral rock - on peat, clay or chalk',
  'a1.1': 'a1.1 - High energy littoral rock',
  'A1.2 PCC': 'A1.2 PCC - Moderate energy littoral rock - on peat, clay or chalk',
  'A1.2': 'A1.2 - Moderate energy littoral rock',
  'A1.3 PCC': 'A1.3 PCC - Low energy littoral rock - on peat, clay or chalk',
  'A1.3': 'A1.3 - Low energy littoral rock',
  'A1.4': 'A1.4 - Features of littoral rock',
  'A1.PCC': 'A1.PCC - Features of littoral rock - on peat, clay or chalk',
  'A2.1': 'A2.1 - Littoral coarse sediment',
  'A2.21/A2.22/A2.23': 'A2.21/A2.22/A2.23 - Littoral sand',
  'A2.24': 'A2.24 - Littoral muddy sand',
  'A2.3': 'A2.3 - Littoral mud',
  'A2.4': 'A2.4 - Littoral mixed sediments',
  'A2.5': 'A2.5 - Saltmarshes and saline reedbeds',
  'A2.6 PCC': 'A2.6 PCC - Littoral seagrass on peat, clay or chalk',
  'A2.6': 'A2.6 - Littoral seagrass',
  'A2.7 M': 'A2.7 M - Littoral biogenic reefs - Mussels',
  'A2.7 S': 'A2.7 S - Littoral biogenic reefs - Sabellaria',
  'A2.8': 'A2.8 - Features of littoral sediment',
  'ART_A1.4': 'ART_A1.4 - Artificial features of hard structures',
  'ART_A2.1': 'ART_A2.1 - Artificial littoral coarse sediment',
  'ART_A2.21/A2.22/A2.23': 'ART_A2.21/A2.22/A2.23 - Artificial littoral sand',
  'ART_A2.24': 'ART_A2.24 - Artificial littoral muddy sand',
  'ART_A2.3': 'ART_A2.3 - Artificial littoral mud',
  'ART_A2.4': 'ART_A2.4 - Artificial littoral mixed sediments',
  'ART_A2.5': 'ART_A2.5 - Artificial saltmarshes and saline reedbeds',
  'ART_A2.6': 'ART_A2.6 - Artificial littoral seagrass',
  'ART_A2.7': 'ART_A2.7 - Artificial littoral biogenic reefs',
  ART_A1_IGGI:
    'ART_A1_IGGI - Artificial hard structures with integrated greening of grey infrastructure (IGGI)',
  ART_A1: 'ART_A1 - Artificial hard structures',
  c1a5: 'c1a5 - Arable field margins tussocky',
  c1a6: 'c1a6 - Arable field margins pollen and nectar',
  c1a7: 'c1a7 - Arable field margins cultivated annually',
  c1a8: 'c1a8 - Arable field margins game bird mix',
  c1b: 'c1b - Temporary grass and clover leys',
  c1c: 'c1c - Cereal crops',
  c1c5: 'c1c5 - Winter stubble',
  c1d: 'c1d - Non-cereal crops',
  c1e: 'c1e - Intensive orchards',
  c1f: 'c1f - Horticulture',
  f1a: 'f1a - Blanket bog',
  f1b: 'f1b - Lowland raised bog',
  f2b: 'f2b - Purple moor grass and rush pastures',
  f2e: 'f2e - Reedbeds',
  g1a: 'g1a - Lowland dry acid grassland',
  g1b: 'g1b - Upland acid grassland',
  g1c: 'g1c - Bracken',
  g1d: 'g1d - Other lowland acid grassland',
  g2a: 'g2a - Lowland calcareous grassland',
  g2b: 'g2b - Upland calcareous grassland',
  g3a: 'g3a - Lowland meadows',
  g3b: 'g3b - Upland hay meadows',
  g3c: 'g3c - Other neutral grassland',
  g4: 'g4 - Modified grassland',
  h1a: 'h1a - Lowland heathland',
  h1b: 'h1b - Upland heathland',
  h1c: 'h1c - Mountain heaths and willow scrub',
  h3a: 'h3a - Blackthorn scrub',
  h3b: 'h3b - Hazel scrub',
  h3c5: 'h3c5 - Dunes with sea buckthorn (H2160)',
  h3c6: 'h3c6 - Other sea buckthorn scrub',
  h3d: 'h3d - Bramble scrub',
  h3e: 'h3e - Gorse scrub',
  h3f: 'h3f - Hawthorn scrub',
  h3g: 'h3g - Rhododendron scrub',
  h3h: 'h3h - Mixed scrub',
  h3j: 'h3j - Willow scrub',
  NE0001: 'NE0001 - High alkalinity lakes',
  NE0002: 'NE0002 - Low alkalinity lakes',
  NE0003: 'NE0003 - Marl lakes',
  NE0004: 'NE0004 - Moderate alkalinity lakes',
  NE0005: 'NE0005 - Peat lakes',
  NE0006: 'NE0006 - Ponds (priority habitat)',
  NE0010: 'NE0010 - Oceanic valley mire[1] (D2.1)',
  NE0011: 'NE0011 - Floodplain wetland mosaic and CFGM',
  NE0013: 'NE0013 - Temporary lakes ponds and pools (H3170)',
  NE0014: 'NE0014 - Urban tree',
  NE0016: 'NE0016 - Rural tree',
  NE0017: 'NE0017 - Watercourse footprint',
  NE0020: 'NE0020 - Fens (upland and lowland)',
  NE0021: 'NE0021 - Transition mires and quaking bogs (H7140)',
  r1d: 'r1d - Aquifer fed naturally fluctuating water bodies',
  s1a: 's1a - Inland rock outcrop and scree habitats',
  s1a9: 's1a9 - Tall herb communities (H6430)',
  s1b: 's1b - Limestone pavement',
  s1c: 's1c - Calaminarian grasslands',
  s1d: 's1d - Other inland rock and scree',
  s2a: 's2a - Maritime cliff and slopes',
  s3a: 's3a - Coastal sand dunes',
  s3b: 's3b - Coastal vegetated shingle',
  u1b: 'u1b - Developed land; sealed surface',
  u1c: 'u1c - Artificial unvegetated, unsealed surface',
  u1e: 'u1e - Built linear features',
  w1a: 'w1a - Upland oakwood',
  w1b: 'w1b - Upland mixed ashwoods',
  w1c: 'w1c - Lowland beech and yew woodland',
  w1d: 'w1d - Wet woodland',
  w1e: 'w1e - Upland birchwoods',
  w1f: 'w1f - Lowland mixed deciduous woodland',
  w1g: 'w1g - Other woodland; broadleaved',
  w1h: 'w1h - Other woodland; mixed',
  w2a: 'w2a - Native pine woodlands',
  w2b: "w2b - Other Scot's pine woodland",
  w2c: 'w2c - Other coniferous woodland',
  x02: 'x02 - Coastal lagoons',
};
