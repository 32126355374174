import { Comment, AddComment, CommentsDisabled } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import mapboxgl, { LngLat } from 'mapbox-gl';
import { Root, createRoot } from 'react-dom/client';
import { useState } from 'react';
import { ICoordinateComments } from '../../core/interfaces/coordinate-comments.interface';

export class CommentControl implements mapboxgl.IControl {
  private container: HTMLElement;
  private root: Root;
  private map: mapboxgl.Map;
  private popups: mapboxgl.Popup[] = [];
  private popupsOpen = true;
  private onUpdate?: (coordinateComments: ICoordinateComments) => void;
  private coordinateComment?: ICoordinateComments[];

  constructor(
    private readonly habitatOptions = [],
    onUpdate: (coordinateComments: ICoordinateComments) => void,
    coordinateComment: ICoordinateComments[],
  ) {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.root = createRoot(this.container);
    this.onUpdate = onUpdate;
    this.coordinateComment = coordinateComment;

    this.render();
  }

  update(coordinateComment: ICoordinateComments[]) {
    console.log(coordinateComment, 'Control comment');
    this.coordinateComment = coordinateComment;
    this.createPopupsFromComments();
    this.render();
  }

  render() {
    this.root.render(
      <Box>
        <AddCommentComponent onAddComment={() => this.onAddComment()} />
        <CloseCommentsComponent onCloseComments={() => this.togglePopups()} />
      </Box>,
    );
  }

  onAdd(map) {
    this.map = map;
    this.createPopupsFromComments(); // Call this method when the control is added to the map
    return this.container;
  }

  private createPopupsFromComments() {
    if (!this.coordinateComment || this.coordinateComment.length === 0) return;

    console.log('Processing coordinate comments:', this.coordinateComment); // Log the coordinate comments

    this.coordinateComment?.forEach((comment) => {
      console.log('api call coordinates', comment);

      const lngLat = this.parseCoordinate(comment.coordinate);
      if (lngLat) {
        const popup = new mapboxgl.Popup({ closeOnClick: false })
          .setLngLat(lngLat)
          .setDOMContent(this.createPopupContentFromComment(comment))
          .addTo(this.map);
        this.popups.push(popup);
      }
    });
  }

  private parseCoordinate(coordinate: string | object): mapboxgl.LngLat | null {
    if (coordinate) {
      const { lng, lat } = coordinate as { lng: number; lat: number };
      return new mapboxgl.LngLat(lng, lat);
    }
    return null;
  }

  private createPopupContentFromComment(comment: ICoordinateComments) {
    const popupContainer = document.createElement('div');
    popupContainer.style.display = 'flex';
    popupContainer.style.flexDirection = 'column';
    popupContainer.style.alignItems = 'center';
    popupContainer.style.textAlign = 'center';
    popupContainer.style.width = '150px';

    const messageContainer = document.createElement('div');
    messageContainer.style.width = '150px';
    messageContainer.style.maxHeight = '4.5em';
    messageContainer.style.overflow = 'hidden';
    messageContainer.style.display = '-webkit-box';
    messageContainer.style.webkitBoxOrient = 'vertical';
    messageContainer.style.webkitLineClamp = '3';
    messageContainer.style.textOverflow = 'ellipsis';
    messageContainer.style.lineHeight = '1.5em';
    messageContainer.style.textAlign = 'left'; // Align text to the left
    messageContainer.style.cursor = 'pointer'; // Change cursor to pointer

    messageContainer.innerHTML = `<strong> Comments: ${comment.comment} <br/> Habitat option: ${comment.habitatType} </strong>`;

    // Add click event to toggle full comment visibility
    messageContainer.onclick = () => {
      if (messageContainer.style.maxHeight === 'none') {
        messageContainer.style.maxHeight = '4.5em'; // Collapse
        messageContainer.style.display = '-webkit-box'; // Show truncated
      } else {
        messageContainer.style.maxHeight = 'none'; // Expand
        messageContainer.style.display = 'block'; // Show full
      }
    };

    popupContainer.appendChild(messageContainer);

    return popupContainer;
  }

  private handleMouseDown = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    let currentPopup = null;
    if (!this.popupsOpen) return;

    if (currentPopup) {
      currentPopup.setLngLat(e.lngLat);
      return;
    }
    currentPopup = new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat(e.lngLat)
      .setDOMContent(this.createPopupContent(e.lngLat))
      .addTo(this.map);

    this.popups.push(currentPopup);

    const originalSetDOMContent = currentPopup.setDOMContent.bind(currentPopup);
    currentPopup.setDOMContent = (content) => {
      originalSetDOMContent(content);
      currentPopup.remove();
      currentPopup = null;
    };

    setTimeout(() => {
      this.map.off('mousedown', this.handleMouseDown);
    }, 0);
  };

  onAddComment() {
    this.map?.on('mousedown', this.handleMouseDown);
  }

  createPopupContent(lngLat) {
    const popupContainer = document.createElement('div');
    popupContainer.style.display = 'flex';
    popupContainer.style.flexDirection = 'column';
    popupContainer.style.alignItems = 'center';
    popupContainer.style.textAlign = 'center';
    popupContainer.style.width = '150px';

    const title = document.createElement('h3');
    title.innerText = 'Comments';

    const input = document.createElement('textarea');
    input.placeholder = 'Enter your comments';
    input.style.width = '150px';
    input.style.height = '75px';
    input.style.marginBottom = '8px';

    const submitButton = document.createElement('button');
    submitButton.innerText = 'Submit';

    // Create a checkbox
    const checkboxContainer = document.createElement('div');
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.id = 'optionsCheckbox';

    const checkboxLabel = document.createElement('label');
    checkboxLabel.htmlFor = 'optionsCheckbox';
    checkboxLabel.innerText = 'Add note to BNG metric';

    // Create a dropdown (select) element
    const dropdown = document.createElement('select');
    dropdown.style.display = 'none'; // Initially hidden
    dropdown.style.marginBottom = '8px';
    dropdown.style.width = '150px';
    dropdown.style.padding = '4px';

    // Add options to the dropdown
    this.habitatOptions.forEach((optionValue) => {
      const option = document.createElement('option');
      option.value = optionValue;
      option.innerText = optionValue;
      dropdown.appendChild(option);
    });

    // Event listener to show/hide dropdown based on checkbox state
    checkbox.addEventListener('change', () => {
      dropdown.style.display = checkbox.checked ? 'block' : 'none';
    });

    const newPopup = new mapboxgl.Popup({ closeOnClick: false }).setLngLat(lngLat);

    submitButton.onclick = async () => {
      const message = input.value;
      const coordinates = lngLat;
      console.log('lnglat obj', lngLat);
      console.log('Message:', message);
      const coordinateComments: ICoordinateComments = {
        comment: message,
        coordinate: coordinates,
        bngMetric: checkbox.checked,
        habitatType: dropdown.value,
      };

      try {
        const response = await this.onUpdate?.(coordinateComments);
        console.log('Comment saved successfully:', message);

        const messageContainer = document.createElement('div');
        messageContainer.style.width = '150px';
        messageContainer.style.maxHeight = '4.5em';
        messageContainer.style.overflow = 'hidden';
        messageContainer.style.display = '-webkit-box';
        messageContainer.style.webkitBoxOrient = 'vertical';
        messageContainer.style.webkitLineClamp = '3';
        messageContainer.style.textOverflow = 'ellipsis';
        messageContainer.style.lineHeight = '1.5em';
        messageContainer.style.textAlign = 'left';
        messageContainer.style.cursor = 'pointer';

        messageContainer.innerHTML = `<strong> Comments: ${message} <br/> Habitat option: ${dropdown.value} </strong>`;

        messageContainer.innerHTML = `<strong> Habitat option:  Comments: </strong>`;

        // Add click event to toggle full comment visibility
        messageContainer.onclick = () => {
          if (messageContainer.style.maxHeight === 'none') {
            messageContainer.style.maxHeight = '4.5em'; // Collapse
            messageContainer.style.display = '-webkit-box'; // Show truncated
          } else {
            messageContainer.style.maxHeight = 'none'; // Expand
            messageContainer.style.display = 'block'; // Show full
          }
        };

        newPopup.setDOMContent(messageContainer).addTo(this.map);
        this.popups.push(newPopup);
        popupContainer.remove();
      } catch (error) {
        console.error('Failed to save comment:', error);
      }
    };

    // Append elements to the popup container
    popupContainer.appendChild(title);
    popupContainer.appendChild(input);
    popupContainer.appendChild(checkboxContainer);
    checkboxContainer.appendChild(checkbox);
    checkboxContainer.appendChild(checkboxLabel);
    popupContainer.appendChild(dropdown); // Ensure dropdown is appended here
    popupContainer.appendChild(submitButton);

    return popupContainer;
  }

  togglePopups() {
    console.log('popups', this.popups, this.coordinateComment);

    if (this.popupsOpen) {
      this.closeAllPopups();
    } else {
      this.popupsOpen = true;
      this.createPopupsFromComments();
    }
  }

  closeAllPopups() {
    this.popups.forEach((popup) => popup.remove());
    // this.popups = [];
    this.popupsOpen = false;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

const ControlContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '4px',
});

export const AddCommentComponent = ({ onAddComment }) => {
  return (
    <ControlContainer id="map-edit-polygon-control">
      <IconButton
        title="Add comment"
        size="small"
        onClick={() => {
          onAddComment();
        }}
        sx={{
          '& .MuiSvgIcon-root': { fontSize: '20px', color: 'black' },
        }}
      >
        <AddComment />
      </IconButton>
    </ControlContainer>
  );
};

export const CloseCommentsComponent = ({ onCloseComments }) => {
  const [commentsOpen, setCommentsOpen] = useState(false);

  const handleToggleComments = () => {
    setCommentsOpen(!commentsOpen);
    onCloseComments();
  };

  return (
    <ControlContainer id="map-close-comments-control" sx={{ marginTop: '8px' }}>
      <IconButton
        title="Toggle comments"
        size="small"
        onClick={handleToggleComments}
        sx={{
          '& .MuiSvgIcon-root': { fontSize: '20px', color: 'black' },
        }}
      >
        {commentsOpen ? <Comment /> : <CommentsDisabled />}
      </IconButton>
    </ControlContainer>
  );
};
